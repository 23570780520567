import React, { useState, useMemo, useEffect } from 'react'
import { Form, Field } from 'react-final-form'
import { Button, TextField, Autocomplete } from '@mui/material'
import { Apartment, Home, Person, Add, Edit } from '@mui/icons-material'
import PhoneNumberInput from 'react-phone-number-input'
import { isEmpty } from 'lodash'
import { navigate } from 'gatsby-link'
import countryList from 'react-select-country-list'
import { useLocation } from '@reach/router'

import { Address } from '../../helpers/types'
import { useProfileContext } from '../../contexts/profileContext'
import { FormContainer, FieldContainer, Text, StyledTextField } from './Styled'
import { getParams } from '../../helpers/urlParams'
import { SubContainer } from '../common/Styled'
import { routePaths } from '../../constants/routes'
import Header from '../common/Header'
import PhoneInput from './PhoneInput'
import Loader from '../common/Loader'

import 'react-phone-number-input/style.css'
import {
  createAddress,
  getAddress,
  updateAddress
} from '../../helpers/api/addresses'
import { useAppContext } from '../../contexts/appContext'

const AddressForm = () => {
  const location = useLocation()
  const [initialAddress, setInitialAddress] = useState<Address>({})
  const id = getParams('id')?.id as string
  const [loading, setLoading] = useState(id ? true : false)
  const state = location.state as { redirection: string }
  const profile = useProfileContext()
  const showSnackbar = useAppContext()?.showSnackbar
  const user = profile?.user
  const countryOptions = useMemo(() => countryList().getData(), [])

  const isEditing = !!id

  useEffect(() => {
    const fetchAddress = async () => {
      setLoading(true)
      const result = await getAddress(id)
      setInitialAddress(result)
      setLoading(false)
    }
    if (isEditing) fetchAddress()
  }, [])

  const handleSubmit = async values => {
    const { country } = values
    const data = {
      ...values,
      country: country.label
    }
    try {
      if (id) {
        await updateAddress(id, data)
        navigate(state?.redirection || routePaths.addresses)
      } else {
        const result = await createAddress(data)

        navigate(
          state?.redirection ? state.redirection : routePaths.addresses,
          {
            state: state?.redirection ? { addressId: result.id } : null
          }
        )
      }
    } catch (e) {
      showSnackbar('error')
    }
  }

  const title = isEditing ? 'Modifier mon adresse' : 'Ajouter une adresse'

  const items = [
    {
      icon: <Home />,
      label: 'Accueil',
      route: routePaths.home
    },
    {
      icon: <Person />,
      label: 'Mon compte',
      route: routePaths.profile
    },
    {
      icon: <Apartment />,
      label: 'Mes adresses',
      route: routePaths.addresses
    },
    {
      icon: isEditing ? <Edit /> : <Add />,
      label: title
    }
  ]

  if (!user) return null

  return (
    <>
      <SubContainer>
        <Header {...{ items, title }} />
        {loading && <Loader />}
        {((id && !loading) || !id) && (
          <Form
            onSubmit={handleSubmit}
            initialValues={{
              ...initialAddress,
              country: countryOptions.find(
                option => option.label === initialAddress?.country
              ) || { value: 'FR', label: 'France' }
            }}
            validate={values => {
              const errors: any = {}
              if (!values.addressName) errors.addressName = 'Required'
              if (!values.firstName) errors.firstName = 'Required'
              if (!values.lastName) errors.lastName = 'Required'
              if (!values.address1) errors.address1 = 'Required'
              if (!values.postalCode) errors.postalCode = 'Required'
              if (!values.city) errors.city = 'Required'
              if (!values.country) errors.country = 'Required'
              if (!values.mobilePhone && !values.landlinePhone)
                errors.mobilePhone = 'Required'
              if (!values.landlinePhone && !values.mobilePhone)
                errors.landlinePhone = 'Required'
              if (values.country && values.country.value !== 'FR')
                errors.country =
                  "Nous n'effectuons que des livraisons dans la France"
              return errors
            }}
            render={({
              handleSubmit,
              pristine,
              submitting,
              errors,
              initialValues
            }) => (
              <FormContainer onSubmit={handleSubmit}>
                <FieldContainer>
                  <Field name="addressName">
                    {({ input, meta }) => (
                      <StyledTextField
                        {...input}
                        error={meta.touched && meta.error}
                        label="Nom de l'adresse"
                        variant="outlined"
                        type="text"
                        required
                      />
                    )}
                  </Field>
                </FieldContainer>
                <FieldContainer>
                  <Field name="firstName">
                    {({ input, meta }) => (
                      <StyledTextField
                        {...input}
                        error={meta.touched && meta.error}
                        label="Prénom"
                        variant="outlined"
                        type="text"
                        required
                      />
                    )}
                  </Field>
                  <Field name="lastName">
                    {({ input, meta }) => (
                      <StyledTextField
                        {...input}
                        error={meta.touched && meta.error}
                        label="Nom"
                        variant="outlined"
                        type="text"
                        required
                      />
                    )}
                  </Field>
                </FieldContainer>
                <FieldContainer>
                  <Field name="company">
                    {({ input, meta }) => (
                      <StyledTextField
                        {...input}
                        error={meta.touched && meta.error}
                        label="Société"
                        variant="outlined"
                        type="text"
                      />
                    )}
                  </Field>
                  <Field name="address1">
                    {({ input, meta }) => (
                      <StyledTextField
                        {...input}
                        error={meta.touched && meta.error}
                        label="Adresse 1"
                        variant="outlined"
                        type="text"
                        required
                      />
                    )}
                  </Field>
                  <Field name="address2">
                    {({ input, meta }) => (
                      <StyledTextField
                        {...input}
                        error={meta.touched && meta.error}
                        label="Adresse 2"
                        variant="outlined"
                        type="text"
                      />
                    )}
                  </Field>
                </FieldContainer>
                <FieldContainer>
                  <Field name="postalCode">
                    {({ input, meta }) => (
                      <StyledTextField
                        {...input}
                        error={meta.touched && meta.error}
                        label="Code Postal"
                        variant="outlined"
                        type="text"
                        inputMode="numeric"
                      />
                    )}
                  </Field>
                  <Field name="city">
                    {({ input, meta }) => (
                      <StyledTextField
                        {...input}
                        error={meta.touched && meta.error}
                        label="Ville"
                        variant="outlined"
                        type="text"
                      />
                    )}
                  </Field>
                  <Field name="country">
                    {({ input, meta }) => (
                      <Autocomplete
                        id="combo-box-demo"
                        options={countryOptions}
                        value={countryOptions.find(
                          option => option.value === input.value
                        )}
                        defaultValue={initialValues.country}
                        onChange={(_, newValue) => input.onChange(newValue)}
                        renderInput={params => (
                          <StyledTextField
                            {...params}
                            error={meta.error}
                            helperText={meta.error !== 'Required' && meta.error}
                            label="Pays"
                            variant="outlined"
                            type="text"
                          />
                        )}
                      />
                    )}
                  </Field>
                </FieldContainer>
                <FieldContainer>
                  <Field name="mobilePhone">
                    {({ input, meta }) => (
                      <PhoneNumberInput
                        {...input}
                        inputComponent={PhoneInput}
                        defaultCountry="FR"
                        error={meta.touched && meta.error}
                        label="Téléphone portable **"
                        type="text"
                        inputMode="tel"
                      />
                    )}
                  </Field>
                  <Field name="landlinePhone">
                    {({ input, meta }) => (
                      <PhoneNumberInput
                        {...input}
                        defaultCountry="FR"
                        inputComponent={PhoneInput}
                        error={meta.touched && meta.error}
                        label="Téléphone fixe **"
                        type="text"
                        inputMode="tel"
                      />
                    )}
                  </Field>
                </FieldContainer>
                <FieldContainer>
                  <Field name="otherInformation">
                    {({ input }) => (
                      <TextField
                        {...input}
                        label="Informations supplémentaires"
                        variant="outlined"
                        multiline
                        fullWidth
                        rows={4}
                        type="text"
                      />
                    )}
                  </Field>
                </FieldContainer>
                <Text>
                  ** Vous devez enregistrer au moins un numéro de téléphone.
                </Text>
                <Button
                  disabled={pristine || submitting || !isEmpty(errors)}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Enregistrer
                </Button>
              </FormContainer>
            )}
          />
        )}
      </SubContainer>
    </>
  )
}

export default AddressForm
