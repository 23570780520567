import React from "react";
import { CircularProgress } from "@mui/material";
import { LoaderContainer } from "./Styled";

const Loader = () => (
  <LoaderContainer>
    <CircularProgress color="primary" />
  </LoaderContainer>
);

export default Loader;
