import { TextField, styled, Typography } from "@mui/material";

export const FormContainer = styled("form")`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
`;

export const FieldContainer = styled("div")`
  gap: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const Text = styled(Typography)`
  align-self: baseline;
  margin: 0;
  padding: 0;
`;

export const StyledTextField = styled(TextField)`
  width: 300px;
  @media (max-width: 600px) {
    width: 100%;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: grey;
  }
  button {
    color: white;
  }
`;
