import React from "react";

import AddressForm from "../components/AddressForm";
import { getParams } from "../helpers/urlParams";
import SEO from "../components/common/seo";

const AddressPage = () => {
  const id = getParams("id")?.id;

  return (
    <>
      <SEO title={id ? "Modifier" : "Ajouter"} />
      <AddressForm />
    </>
  );
};

export default AddressPage;
