import axios from 'axios'

import { formatAttributes } from '..'
import { Address } from '../types'

export const createAddress = async (data: Address): Promise<Address> => {
  try {
    const response = await axios.post(
      `${process.env.GATSBY_STRAPI_URL}/api/create-address`,
      { data }
    )
    return response.data
  } catch (e) {
    console.error(e)
    throw new Error('Une erreur est survenue lors de la création')
  }
}

export const getAddresses = async (): Promise<Address[]> => {
  try {
    const response = await axios.get(
      `${process.env.GATSBY_STRAPI_URL}/api/get-addresses`
    )
    return response.data
  } catch (e) {
    console.error(e)
    throw new Error('Une erreur est survenue lors des récupérations')
  }
}

export const getAddress = async (addressId: string): Promise<Address> => {
  try {
    const response = await axios.get(
      `${process.env.GATSBY_STRAPI_URL}/api/addresses/${addressId}`
    )
    return formatAttributes(response.data.data)
  } catch (e) {
    console.error(e)
    throw new Error('Une erreur est survenue lors de la récuperation')
  }
}

export const updateAddress = async (
  addressId: string,
  data: Address
): Promise<Address[]> => {
  try {
    const response = await axios.put(
      `${process.env.GATSBY_STRAPI_URL}/api/update-address/${addressId}`,
      { data }
    )
    return formatAttributes(response.data)
  } catch (e) {
    console.error(e)
    throw new Error("Une erreur est survenue lors de l'édition")
  }
}

export const deleteAddress = async (addressId: string) => {
  try {
    await axios.delete(
      `${process.env.GATSBY_STRAPI_URL}/api/delete-address/${addressId}`
    )
  } catch (e) {
    console.error(e)
    throw new Error('Une erreur est survenue lors de la suppression')
  }
}
